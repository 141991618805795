import {
  JURISIDICTION_PREFIX,
  KEY_LS_ADMINTYPE,
  KEY_LS_CID,
  KEY_LS_COMPANYTYPE,
  KEY_LS_LOGO_PREFIX,
  KEY_LS_REFERRER,
  KEY_LS_SIDE_MENU,
  KEY_LS_USERNAME,
  KEY_LS_USERTYPE,
} from '.';
import { AppRoutings } from '../enums/app-routings';
import { COUNTRY_CODES } from './countries';
import { getWebPrefix } from './server_details';

export const CID_DEFAULT = -1;
// Start: specific customer CIDs
export const CID_DEMO = 1;
export const CID_A2B = 0;
export const CID_ABSE = 5;
export const CID_GUERIN = 10;
export const CID_SOMPO = 17;
export const CID_TRIPA = 44;
export const CID_CARBIZ = 55;
export const CID_AAMG_RENTAL = 55;
export const CID_DEMO_INSURANCE = 57;
export const CID_MTAG = 74;
export const CID_GOLDBELL = 1046;

// End: specific customer CIDs

export enum COMPANY_TYPES {
  RENTAL,
  INSURANCE,
  DEALER,
  REPAIRER,
  RENTAL_FINDER,
}

// TODO: phase this out by adding to DynamoDb company_settings.profile.companytype
const __CID_COMPANY_TYPES = {
  [COMPANY_TYPES.INSURANCE]: [CID_SOMPO, CID_TRIPA, CID_DEMO_INSURANCE],
  [COMPANY_TYPES.DEALER]: [61, 73, 74],
  [COMPANY_TYPES.REPAIRER]: [63, 65, 66, 67, 69, 70, 75, 76],
  [COMPANY_TYPES.RENTAL_FINDER]: [CID_AAMG_RENTAL],
  [COMPANY_TYPES.RENTAL]: [0, 5, 68, 71, 1046],
};

const _TRIAL_COMPANIES = [58, 59, 60, 61, 62, 64];

const COUNTRY_COMPANIES = {
  [COUNTRY_CODES.AUSTRALLIA]: [CID_ABSE, CID_DEMO, CID_CARBIZ, CID_AAMG_RENTAL],
  [COUNTRY_CODES.INDONESIA]: [CID_SOMPO, CID_TRIPA, CID_DEMO_INSURANCE],
};

export const getCid = (): number => {
  return Number(localStorage.getItem(KEY_LS_CID));
};

export const getCountryCode = (): string => {
  let result = 'default';
  Object.keys(COUNTRY_COMPANIES).forEach((key) => {
    if (COUNTRY_COMPANIES[key].includes(getCid())) {
      result = key;
    }
  });
  return result;
};

/**
 * Returns the relevant configuration for the active cid
 * (either specifically for the cid, or the group it belongs to, in that order).
 * Example mappings that are put in:
 *   constants.index._MAPPING_REVIEWS_EVENT_TYPE
 *   language.index.DEFAULT_LANGUAGE
 * @param mapping
 * @returns
 */
export const getMapForCid = (mapping: any) => {
  let result = mapping['default'];
  const cid = getCid();

  const companyType: string = 1 + '';

  // TODO: this looks clumsy, but quick fix to support legacy mapping
  if (Object.keys(mapping).includes(companyType) && mapping[companyType] !== undefined) {
    result = mapping[companyType];
  }

  // Override if applicable
  if (!(mapping[cid] === undefined)) {
    result = mapping[cid];
  }
  return result;
};

export const getUserType = (): number => {
  let result = parseInt(localStorage.getItem(KEY_LS_USERTYPE) ?? '-1');
  console.log('getUserType', result);
  if (getCid() == CID_DEMO && result >= 1000) {
    // Impersonation of company type and user type
    result = 100 + (result % 10); // last digit is the usertype, second last digit company type
  }
  return result;
};

/**
 * This should be called on "change user type viewpoint" in demo mode only.
 * Provides an impersonation company type based on the user type.
 * @returns company index from COMPANY_TYPES
 */
const _getImpersonationCompanyType = () => {
  const fakeUsertype = parseInt(localStorage.getItem(KEY_LS_USERTYPE) ?? '-1');
  // This is impersonation only
  const fakeCompanyType =
    fakeUsertype >= 1000 ? Math.floor(fakeUsertype / 10) % 10 : fakeUsertype - 100;
  return fakeCompanyType;
};

/**
 * Checks if the current user belongs to a company type.
 * @param companyType the company type being checked
 * @returns true if user belongs to the company type,
 *          false otherwise
 */
export const isCompanyType = (companyType: COMPANY_TYPES): boolean => {
  const cid = getCid();

  // Check for demo override (impersonation for testing purposes)
  if (cid == CID_DEMO) {
    const userType = getUserType();
    if (userType >= 100) {
      // This is impersonation only
      return _getImpersonationCompanyType() == companyType;
    }
    if (userType == 11) {
      return companyType == COMPANY_TYPES.DEALER;
    }
  }

  const foundCompanyType = getCompanyType(cid);
  return foundCompanyType !== null && foundCompanyType == companyType;
};

export const getCompanyType = (cid: number): COMPANY_TYPES | null => {
  let result: COMPANY_TYPES | null = null;

  // Special override for demo account impersonation
  if (cid == CID_DEMO) {
    const userType = getUserType();
    if (userType >= 100) {
      result = _getImpersonationCompanyType();
      return result;
    }
    if (userType == 11) {
      return COMPANY_TYPES.DEALER;
    }
  }

  result = localStorage.getItem(KEY_LS_COMPANYTYPE) // Cached at login if available
    ? Number(localStorage.getItem(KEY_LS_COMPANYTYPE))
    : null;

  if (result === null) {
    // TODO: phase this out
    Object.keys(__CID_COMPANY_TYPES).forEach((key) => {
      if (__CID_COMPANY_TYPES[key].includes(cid)) {
        result = parseInt(key);
        return result; // TODO: return from here is probably not possible, find another way to exit loop
      }
    });
  }

  return result;
};

// TODO: deprecate
export const isInsurance = (): boolean => {
  return isCompanyType(COMPANY_TYPES.INSURANCE);
};

// TODO: deprecate
export const isDealer = (): boolean => {
  return isCompanyType(COMPANY_TYPES.DEALER);
};

// TODO: deprecate
export const isRepairer = (): boolean => {
  const flag = isCompanyType(COMPANY_TYPES.REPAIRER);
  console.log('Repairer mode');
  return flag;
};

// TODO: deprecate
export const isRentalFinder = (): boolean => {
  return isCompanyType(COMPANY_TYPES.RENTAL_FINDER);
};

export const isTrialAccount = (): boolean => {
  return _TRIAL_COMPANIES.includes(getCid());
};

export const isSompo = (): boolean => {
  return getCid() == CID_SOMPO;
};

export enum ACTIVATION_TYPES {
  WEBAPP, // General admission (signin)
  WORKSHOPS,
  WORKSHOPS_EDIT,
  ONE_TIME_USER,
  CAPTURE_CHECK,
  CAPTURE_CHECK_CLAIMS,
  AUDIT_TRAIL,
  CLAIMS_GENERAL,
  CHECKOUT_EDIT,
  CHECKIN_EDIT,
  NEW_POLICIES,
  ASSETS,
  REVIEWS,
  COMPARISONS,
  COMPARISONSDEALERS,
  COMPARISONREPORTS,
  LOCATIONS,
  OPTIONS,
  SCANS,
  SCANSREVIEWLIST,
  SCANS_CHECKIN, // Really used for claims, and to hide for dealers/repairers
  SCANS_INTERMEDIATE_REVIEW, // This is the screen in between the list and the actual review, which can be skipped by deactivating
  REVIEWS_TAB_REVIEW,
  REVIEWS_TAB_EXTRA,
  REVIEWS_TAB_DETAILS,
  REVIEWS_TAB_IMAGES_SIDEBYSIDE,
  DOWNLOAD_PHOTOS,
  ANNOTATIONS,
}

const ALLOW_ALL = -1;

// TODO: this needs to go to database, and also as usertype
// ActivationType -> cid -> [users]
const USER_ACTIVATIONS = {
  [ACTIVATION_TYPES.AUDIT_TRAIL]: {
    [CID_SOMPO]: [
      '17_sompodev',
      '17_admin',
      'arief.hariyanto@sompo.co.id',
      'patria.bayu@sompo.co.id',
      'risa.anggraeni@sompo.co.id',
      'fidi.santoso@sompo.co.id',
      'syarif.usman@sompo.co.id',
      'andreas.adityanto@sompo.co.id',
      'amry.sitompul@sompo.co.id',
    ],
    [CID_DEMO]: ['ptsyddev1', '1_demo'],
    [CID_DEMO_INSURANCE]: ['57_demo', '57_dev'],
  },
};

// Each entry must include CID_DEFAULT, even if it's empty
const _USER_TYPE_ACTIVATIONS = {
  [ACTIVATION_TYPES.WEBAPP]: {
    [CID_SOMPO]: [0, 1, 2, 4, 5],
    [CID_DEFAULT]: [ALLOW_ALL],
    ['COMPANYTYPE' + COMPANY_TYPES.REPAIRER]: [1, 2, 9],
  },
  [ACTIVATION_TYPES.NEW_POLICIES]: {
    [CID_SOMPO]: [0, 1, 2, 4, 5],
    [CID_DEMO_INSURANCE]: [0, 1, 2, 4, 5],
    [CID_DEMO]: [1],
    [CID_DEFAULT]: [],
  },
  [ACTIVATION_TYPES.CLAIMS_GENERAL]: {
    [CID_SOMPO]: [2, 4, 5], // admin and claims and hybrid users
    [CID_DEMO_INSURANCE]: [2, 4, 5], // admin and claims and hybrid users
    [CID_DEMO]: [1],
    [CID_DEFAULT]: [],
  },
  [ACTIVATION_TYPES.ASSETS]: {
    [CID_DEMO]: [1],
    [CID_DEFAULT]: [ALLOW_ALL],
    [CID_GOLDBELL]: [1, 2],
    [59]: [],
    [60]: [],
    [62]: [],
    [63]: [],
    [64]: [],
    [65]: [],
    [66]: [],
    [67]: [],
    [69]: [],
    ['COMPANYTYPE' + COMPANY_TYPES.REPAIRER]: [],
    ['COMPANYTYPE' + COMPANY_TYPES.RENTAL_FINDER]: [],
    ['COMPANYTYPE' + COMPANY_TYPES.DEALER]: [],
  },
  [ACTIVATION_TYPES.SCANS]: {
    [CID_SOMPO]: [1, 2, 4, 5],
    [CID_GOLDBELL]: [1, 2],
    ['COMPANYTYPE' + COMPANY_TYPES.RENTAL_FINDER]: [],
    [CID_DEMO_INSURANCE]: [6],
    [CID_DEMO]: [0, 1, 23],
    [CID_DEFAULT]: [ALLOW_ALL],
    [60]: [],
    [62]: [],
    // Start: active repairers temporarily
    [63]: [ALLOW_ALL],
    [65]: [ALLOW_ALL],
    [66]: [ALLOW_ALL],
    [67]: [ALLOW_ALL],
    [69]: [ALLOW_ALL],
    // End: active repairers temporarily
    ['COMPANYTYPE' + COMPANY_TYPES.DEALER]: [],
    ['COMPANYTYPE' + COMPANY_TYPES.REPAIRER]: [],
  },
  [ACTIVATION_TYPES.SCANSREVIEWLIST]: {
    [CID_DEMO]: [0, 1, 11, 23],
    [CID_DEFAULT]: [],
    // Start: active repairers temporarily
    [63]: [],
    [65]: [],
    [66]: [],
    [67]: [],
    [69]: [],
    // End: active repairers temporarily
    [CID_MTAG]: [1],
    ['COMPANYTYPE' + COMPANY_TYPES.DEALER]: [ALLOW_ALL],
    ['COMPANYTYPE' + COMPANY_TYPES.REPAIRER]: [1, 2, 9],
  },
  [ACTIVATION_TYPES.SCANS_CHECKIN]: {
    [CID_SOMPO]: [2, 4, 5],
    [CID_DEMO_INSURANCE]: [6],
    [CID_DEMO]: [1, 11],
    [CID_DEFAULT]: [ALLOW_ALL],
    ['COMPANYTYPE' + COMPANY_TYPES.REPAIRER]: [1, 2, 9],
    ['COMPANYTYPE' + COMPANY_TYPES.DEALER]: [ALLOW_ALL],
  },
  [ACTIVATION_TYPES.SCANS_INTERMEDIATE_REVIEW]: {
    [CID_DEFAULT]: [ALLOW_ALL],
  },
  [ACTIVATION_TYPES.REVIEWS]: {
    ['COMPANYTYPE' + COMPANY_TYPES.RENTAL_FINDER]: [],
    [CID_DEMO]: [1],
    [CID_DEFAULT]: [ALLOW_ALL],
    [CID_GOLDBELL]: [1, 2],
    [59]: [],
    [60]: [],
    [62]: [],
    [64]: [],
    // Start: active repairers temporarily
    [63]: [ALLOW_ALL],
    [65]: [ALLOW_ALL],
    [66]: [ALLOW_ALL],
    [67]: [ALLOW_ALL],
    [69]: [ALLOW_ALL],
    // End: active repairers temporarily
    ['COMPANYTYPE' + COMPANY_TYPES.DEALER]: [],
    ['COMPANYTYPE' + COMPANY_TYPES.REPAIRER]: [],
  },
  [ACTIVATION_TYPES.REVIEWS_TAB_REVIEW]: {
    [CID_DEFAULT]: [ALLOW_ALL],
    // Start: active repairers temporarily
    [63]: [ALLOW_ALL],
    [65]: [ALLOW_ALL],
    [66]: [ALLOW_ALL],
    [67]: [ALLOW_ALL],
    [69]: [ALLOW_ALL],
    // End: active repairers temporarily
    ['COMPANYTYPE' + COMPANY_TYPES.DEALER]: [],
    ['COMPANYTYPE' + COMPANY_TYPES.REPAIRER]: [],
  },
  [ACTIVATION_TYPES.REVIEWS_TAB_IMAGES_SIDEBYSIDE]: {
    [CID_DEFAULT]: [],
    [CID_ABSE]: [ALLOW_ALL],
    [CID_A2B]: [ALLOW_ALL],
    [CID_DEMO]: [ALLOW_ALL],
  },
  [ACTIVATION_TYPES.REVIEWS_TAB_EXTRA]: {
    [CID_DEFAULT]: [ALLOW_ALL],
  },
  [ACTIVATION_TYPES.REVIEWS_TAB_DETAILS]: {
    [CID_DEFAULT]: [ALLOW_ALL],
  },
  [ACTIVATION_TYPES.DOWNLOAD_PHOTOS]: {
    [CID_DEFAULT]: [ALLOW_ALL],
  },
  [ACTIVATION_TYPES.COMPARISONS]: {
    [CID_DEMO]: [1],
    [CID_DEFAULT]: [],
    [58]: [ALLOW_ALL], // Expo
    [59]: [ALLOW_ALL],
    [60]: [ALLOW_ALL],
    [62]: [ALLOW_ALL],
    [64]: [ALLOW_ALL],
  },
  [ACTIVATION_TYPES.COMPARISONSDEALERS]: {
    [CID_DEMO]: [1, 11],
    [CID_DEFAULT]: [],
    // Start: active repairers temporarily
    [63]: [],
    [65]: [],
    [66]: [],
    [67]: [],
    [69]: [],
    // End: active repairers temporarily
    [CID_MTAG]: [1],
    ['COMPANYTYPE' + COMPANY_TYPES.DEALER]: [ALLOW_ALL],
    ['COMPANYTYPE' + COMPANY_TYPES.REPAIRER]: [1, 2, 9],
  },
  [ACTIVATION_TYPES.COMPARISONREPORTS]: {
    [CID_DEMO]: [1, 11],
    [CID_DEFAULT]: [],
    [58]: [ALLOW_ALL], // Expo
    // Start: active repairers temporarily
    [63]: [],
    [65]: [],
    [66]: [],
    [67]: [],
    [69]: [],
    // End: active repairers temporarily
    [CID_MTAG]: [1],
    ['COMPANYTYPE' + COMPANY_TYPES.DEALER]: [ALLOW_ALL],
    ['COMPANYTYPE' + COMPANY_TYPES.REPAIRER]: [1, 2, 9],
  },
  [ACTIVATION_TYPES.LOCATIONS]: {
    [CID_SOMPO]: [1, 2, 4, 5],
    [CID_GOLDBELL]: [1, 2],
    [CID_DEMO_INSURANCE]: [1, 2, 4, 5],
    ['COMPANYTYPE' + COMPANY_TYPES.RENTAL_FINDER]: [],
    ['COMPANYTYPE' + COMPANY_TYPES.REPAIRER]: [1, 2, 9],
    [CID_DEMO]: [ALLOW_ALL],
    [CID_DEFAULT]: [ALLOW_ALL],
  },
  [ACTIVATION_TYPES.OPTIONS]: {
    ['COMPANYTYPE' + COMPANY_TYPES.RENTAL_FINDER]: [],
    [CID_DEMO]: [ALLOW_ALL],
    [CID_DEFAULT]: [ALLOW_ALL],
    ['COMPANYTYPE' + COMPANY_TYPES.REPAIRER]: [1, 2, 9],
  },
  [ACTIVATION_TYPES.CHECKOUT_EDIT]: {
    [CID_SOMPO]: [1, 2, 5], // new-business, admin and IT
    [CID_DEMO_INSURANCE]: [1, 2, 5], // new-business, admin and IT
    [CID_DEMO]: [ALLOW_ALL],
    [CID_DEFAULT]: [ALLOW_ALL],
  },
  [ACTIVATION_TYPES.CHECKIN_EDIT]: {
    [CID_SOMPO]: [4, 2, 5], // claims, admin and IT
    [CID_DEMO_INSURANCE]: [4, 2, 5], // claims, admin and IT
    [CID_DEMO]: [ALLOW_ALL],
    [CID_DEFAULT]: [ALLOW_ALL],
  },
  [ACTIVATION_TYPES.ONE_TIME_USER]: {
    [CID_SOMPO]: [1, 2, 4, 5],
    [CID_DEMO_INSURANCE]: [2, 5],
    19: [ALLOW_ALL],
    [CID_DEMO]: [1],
    ['COMPANYTYPE' + COMPANY_TYPES.RENTAL_FINDER]: [],
    [CID_DEFAULT]: [],
    // ['COMPANYTYPE' + COMPANY_TYPES.REPAIRER]: [1, 2,9], // Not activated yet
  },
  [ACTIVATION_TYPES.CAPTURE_CHECK]: {
    [CID_SOMPO]: [1, 2, 4, 5],
    [CID_DEMO_INSURANCE]: [1, 2, 4, 5],
    19: [ALLOW_ALL],
    56: [ALLOW_ALL],
    [CID_DEMO]: [1],
    [CID_DEFAULT]: [],
  },
  [ACTIVATION_TYPES.CAPTURE_CHECK_CLAIMS]: {
    [CID_SOMPO]: [2, 4, 5],
    [CID_DEMO_INSURANCE]: [2, 4, 5],
    19: [ALLOW_ALL],
    56: [ALLOW_ALL],
    [CID_DEMO]: [1],
    [CID_DEFAULT]: [],
  },
  [ACTIVATION_TYPES.WORKSHOPS]: {
    [CID_SOMPO]: [2, 5],
    [CID_DEMO_INSURANCE]: [2, 5],
    19: [ALLOW_ALL],
    [CID_CARBIZ]: [ALLOW_ALL],
    ['COMPANYTYPE' + COMPANY_TYPES.RENTAL_FINDER]: [ALLOW_ALL],
    [CID_DEFAULT]: [],
  },
  [ACTIVATION_TYPES.WORKSHOPS_EDIT]: {
    // Includes batch upload
    [CID_SOMPO]: [5],
    19: [ALLOW_ALL],
    [CID_CARBIZ]: [ALLOW_ALL],
    ['COMPANYTYPE' + COMPANY_TYPES.RENTAL_FINDER]: [ALLOW_ALL],
    [CID_DEMO_INSURANCE]: [5],
    [CID_DEFAULT]: [],
  },
  [ACTIVATION_TYPES.ANNOTATIONS]: {
    [CID_DEFAULT]: [],
    [CID_DEMO]: [22],
    [CID_MTAG]: [22],
  },
};

export const isActivatedForUser = (activationType) => {
  if (
    activationType in USER_ACTIVATIONS &&
    Object.keys(USER_ACTIVATIONS[activationType]).includes(getCid() + '')
  ) {
    return USER_ACTIVATIONS[activationType][getCid()].includes(
      localStorage.getItem(KEY_LS_USERNAME)
    );
  }
  return false;
};

export const isActivatedForUserType = (activationType) => {
  if (activationType in _USER_TYPE_ACTIVATIONS) {
    let targetCid = CID_DEFAULT + '';
    const cid = getCid();
    const demo_override = cid == CID_DEMO && getUserType() >= 100;
    if (!demo_override && Object.keys(_USER_TYPE_ACTIVATIONS[activationType]).includes(cid + '')) {
      targetCid = cid + '';
    } else {
      // Check if there's something generic for company type
      // TODO: cache this, but robust to changes in user type, logging out/in, etc.
      const companyType = getCompanyType(cid);
      if (companyType !== null) {
        const key = 'COMPANYTYPE' + companyType;
        const keys = Object.keys(_USER_TYPE_ACTIVATIONS[activationType]);
        if (keys.includes(key)) {
          targetCid = key;
        } // else: remains default
      }
    }

    const permitted = _USER_TYPE_ACTIVATIONS[activationType][targetCid];
    let userType = getUserType();
    if (getCid() == CID_DEMO && userType >= 100) {
      // Impersonation mode
      userType = userType % 10;
    }
    return permitted.includes(ALLOW_ALL) || permitted.includes(userType);
  }
  return false;
};

export const getMenuLabelFor = (label: string): string => {
  // TODO: generalise this
  if (label === 'reviews') {
    return isRepairer() ? 'Comparisons' : 'Reviews';
  }
  return label;
};

export const REGION_MAPPING = {
  [CID_SOMPO + '']: {
    sompo_otc: 'Dev test site',
    sompo_alt: 'Alt test site',
    SMP_MAL: 'Malang',
    SMP_BAT: 'Batam',
    SMP_TAN: 'Tangerang',
    SMP_PAL: 'Palembang',
    SMP_BAN: 'Bandung',
    SMP_YOG: 'Yogyakarta',
    SMP_SUR: 'Surabaya',
    SMP_BAL: 'Balikpapan',
    SMP_SEM: 'Semarang',
    SMP_SOL: 'Solo',
    SMP_PON: 'Pontianak',
    SMP_PAS: 'Pasar Minggu',
    SMP_LAM: 'Bandar Lampung',
    SMP_MAK: 'Makassar',
    SMP_JKS: 'Jakarta Selatan',
    SMP_TEST: 'Test',
    SMP_HQ: 'HQ',
    SMP_MED: 'Medan',
    SMP_KEL: 'Kelapa Gading',
    SMP_DPS: 'Denpasar',
    SMP_PEK: 'Pekanbaru',
  },
  [CID_DEMO_INSURANCE + '']: {
    DEMOINS_TST: 'Jakarta',
  },
};

const PASSWORD_RULES = {
  [CID_SOMPO + '']: { min: 12, hint: 'password-hint-rules-' + CID_SOMPO },
  [CID_DEFAULT + '']: { min: 8, hint: 'password-hint-rules-default' },
};

export const getPasswordRule = (cidInt: number, label: string): string => {
  const cid = cidInt + '';
  if (Object.keys(PASSWORD_RULES).includes(cid)) {
    return PASSWORD_RULES[cid][label];
  }
  return PASSWORD_RULES[CID_DEFAULT + ''][label] + '';
};

export const hideMenuItems = (menuItem: string): boolean => {
  if (menuItem == '/dashboard') {
    if (isRentalFinder() || isRepairer()) {
      return true;
    }
  }
  if (menuItem == '/reviews') {
    console.log(isActivatedForUserType(ACTIVATION_TYPES.REVIEWS));
    if (
      isInsurance() ||
      [CID_GUERIN].includes(getCid()) ||
      !isActivatedForUserType(ACTIVATION_TYPES.REVIEWS)
    ) {
      return true;
    }
  }
  if (menuItem == '/assets') {
    if (
      isInsurance() ||
      [CID_GUERIN].includes(getCid()) ||
      !isActivatedForUserType(ACTIVATION_TYPES.ASSETS)
    ) {
      return true;
    }
  }
  if (menuItem == '/admin-settings') {
    return !(
      (
        isAdmin() ||
        (getWebPrefix().includes('dev') &&
          [CID_DEMO, CID_SOMPO, CID_DEMO_INSURANCE].includes(getCid()) &&
          getUserType() != 22)
      ) // This is for cid==1, but noone else has this usertype
    );
  }

  if (menuItem == AppRoutings.Scans) {
    return !isActivatedForUserType(ACTIVATION_TYPES.SCANS);
  }
  if (menuItem == AppRoutings.ScansReviewList) {
    return !isActivatedForUserType(ACTIVATION_TYPES.SCANSREVIEWLIST);
  }
  if (menuItem == AppRoutings.DamageAnnotationOverview) {
    return !isActivatedForUserType(ACTIVATION_TYPES.ANNOTATIONS);
  }
  if (menuItem == AppRoutings.Comparisons) {
    return !isActivatedForUserType(ACTIVATION_TYPES.COMPARISONS);
  }
  if (menuItem == AppRoutings.ComparisonsDealers) {
    return !isActivatedForUserType(ACTIVATION_TYPES.COMPARISONSDEALERS);
  }
  if (menuItem == AppRoutings.ComparisonReports) {
    return !isActivatedForUserType(ACTIVATION_TYPES.COMPARISONREPORTS);
  }
  if (menuItem == AppRoutings.NewPolicies) {
    return !isActivatedForUserType(ACTIVATION_TYPES.NEW_POLICIES);
  }

  if (menuItem == AppRoutings.WorkshopFinder) {
    return !isActivatedForUserType(ACTIVATION_TYPES.WORKSHOPS);
  }

  if (menuItem == AppRoutings.Locations) {
    return !isActivatedForUserType(ACTIVATION_TYPES.LOCATIONS);
  }

  if (menuItem == AppRoutings.UserOptions) {
    return !isActivatedForUserType(ACTIVATION_TYPES.OPTIONS);
  }

  if (menuItem == AppRoutings.RequestScan) {
    return !isActivatedForUserType(ACTIVATION_TYPES.ONE_TIME_USER) || getCid() == CID_AAMG_RENTAL;
  }

  if (menuItem == AppRoutings.CaptureCheck) {
    return !isActivatedForUserType(ACTIVATION_TYPES.CAPTURE_CHECK);
  }

  if (
    menuItem == AppRoutings.NewClaims
    // Hide until UAT is complete
    //    || menuItem == AppRoutings.Claims
  ) {
    return !isActivatedForUserType(ACTIVATION_TYPES.CLAIMS_GENERAL);
  }

  // Temporary during UAT
  if (menuItem == AppRoutings.Claims) {
    return !isActivatedForUserType(ACTIVATION_TYPES.CLAIMS_GENERAL);
  }

  return false;
};

export const isAdmin = () => {
  const admin = Number(localStorage.getItem(KEY_LS_ADMINTYPE));
  // admin type 1: ProofTec (user type may differ)
  // admin type 9: client account owner (same code has been reserved for user type)
  // admin type 2: client admin user (same code has been reserved for user type)
  return admin === 1 || admin === 2 || admin === 9;
};

export const KEY_LBL_ASSET_ID = 'ASSET_ID';
export const KEY_LBL_RA = 'RA';
export const KEY_LBL_LOCATION = 'LOCATION';
export const KEY_LBL_MAKE = 'MAKE';
export const KEY_LBL_MODEL = 'MODEL';
export const KEY_LBL_EVENTTYPE = 'EVENTTYPE';

// TODO: make keys consistent with constants
const _MAP_LABELS = {
  en: {
    ASSET_ID: 'Asset ID',
    RA: 'RA',
    LOCATION: 'Location',
    EVENTTYPE: 'Direction',
  },
};

const _MAP_LABELS_INSURANCE = {
  en: {
    ASSET_ID: 'Plate',
    RA: 'App No',
    LOCATION: 'Branch name',
  },
};

const _MAP_LABELS_DEALERS = {
  en: {
    ASSET_ID: 'License plate',
    RA: 'Contract',
    LOCATION: 'Branch',
    EVENTTYPE: 'Direction',
  },
};

const _MAP_LABELS_REPAIRERS = {
  en: {
    ASSET_ID: 'License plate',
    RA: 'Contract',
    LOCATION: 'Branch',
  },
};

export const getTextLabel = (key: string): string => {
  let result = _MAP_LABELS['en'][key];
  if (isInsurance()) {
    if (key in _MAP_LABELS_INSURANCE['en']) {
      result = _MAP_LABELS_INSURANCE['en'][key];
    }
  }
  if (isDealer()) {
    if (key in _MAP_LABELS_DEALERS['en']) {
      result = _MAP_LABELS_DEALERS['en'][key];
    }
  }
  if (isRepairer()) {
    if (key in _MAP_LABELS_REPAIRERS['en']) {
      result = _MAP_LABELS_REPAIRERS['en'][key];
    }
  }

  return result == null ? '' : result;
};

const _MAP_WHITE_LABELS = {
  tab_title: {
    default: 'ProofTec Damage Detection',
    26: 'Fluxe',
  },
};

interface IImageInfo {
  src: string;
  width?: number;
  height?: number;
  url?: string;
}

export enum LOGO_TYPES {
  SIGNIN = 'signin_logo',
  SIDEBAR = 'sidebar_logo',
  PUBLIC = 'logo_public',
}

const _MAP_IMAGES = {
  [LOGO_TYPES.SIGNIN]: {
    default: '/assets/logos/signin/prooftec_white_logo_text.svg',
    26: '/assets/logos/sidebar/fluxe_white_on_black.svg',
    [CID_AAMG_RENTAL]: '/assets/logos/sidebar/carbiz_white.png',
  },
  [LOGO_TYPES.SIDEBAR]: {
    default: '/assets/logos/sidebar/prooftec_white_logo_text_horizontal.svg',
    26: '/assets/logos/sidebar/fluxe_white_on_black.svg',
    [CID_AAMG_RENTAL]: '/assets/logos/sidebar/carbiz_white.png',
  },
  [LOGO_TYPES.PUBLIC]: {
    default: { src: `${process.env.PUBLIC_URL}/logo192.png`, width: 70, height: 70 },
    0: {
      src: 'https://www.a2bcarrentals.com.au/wp-content/uploads/2018/04/best-car-hire-company-in-parramatta.png',
      url: '',
    },
    1: { src: `${process.env.PUBLIC_URL}/logo192.png`, width: 70, height: 70 },
    5: {
      src: 'https://avisbudgetgroup.com/wp-content/uploads/2018/10/media-assets-logo-thumb-avisbudgetgroup1-200w.jpg',
    },
    29: { src: 'https://www.europcar.fr/_nuxt/img/europcar-green.ebcf0ee.svg' },
  },
  favicon: {
    default: '',
    26: '',
  },
};

export const setImage = (key: string, url: string) => {
  localStorage.setItem(KEY_LS_LOGO_PREFIX + '_' + getCid() + '_' + key, url);
};

export const getImage = (key: string, unverifiedCID = -1): string => {
  const cid = unverifiedCID < 0 ? getCid() : unverifiedCID;
  let result = '';

  // Check local storage first
  result = localStorage.getItem(KEY_LS_LOGO_PREFIX + '_' + cid + '_' + key) ?? '';
  if (result.length == 0) {
    const data = _MAP_IMAGES[key];
    if (key == LOGO_TYPES.PUBLIC) {
      const tmp = data[cid] != null ? data[cid] : data['default'];
      result = tmp.src;
    } else {
      result = data[cid] != null ? data[cid] : data['default'];
    }
  }
  if (!result.startsWith('http')) {
    result = process.env.PUBLIC_URL + result;
  }
  return result;
};

export const getImageInfo = (key: string, cid = -1): IImageInfo => {
  console.log('getImageInfo', cid);
  let result = { src: '' };
  const data = _MAP_IMAGES[key];
  if (data != null) {
    if (cid < 0 || cid == null) {
      cid = getCid();
      if (cid == null) {
        cid = -1;
      }
    }
    result = data[cid] != null ? data[cid] : data['default'];
  }
  return result;
};

export const getWhiteLabel = (key: string, cid = -1): string => {
  let result = '';
  const data = _MAP_WHITE_LABELS[key];
  if (data != null) {
    if (cid < 0 || cid == null) {
      cid = getCid();
      if (cid == null) {
        cid = -1;
      }
    }

    result = data[cid] != null ? data[cid] : data['default'];
  }
  return result;
};

export const CONFIG_FIELD_OVERVIEW_DISPLAY_MODE = 'reviews_overview_display_mode';
export enum ENUM_FIELD_OVERVIEW_DISPLAY_MODE {
  CANDIDATES_ONLY,
  SHOW_CARS,
  EXPERIMENTAL,
}

export const CONFIG_FIELD_OVERVIEW_CANDIDATE_ORDERING = 'reviews_overview_candidate_ordering';
export enum ENUM_FIELD_OVERVIEW_CANDIDATE_ORDERING {
  SENTIMENT,
  SPATIAL,
}

export const CONFIG_FIELD_OVERVIEW_NUMBER_CANDIDATES = 'reviews_overview_number_candidates';
export const CONFIG_FIELD_OVERVIEW_THRESHOLD = 'reviews_overview_threshold';
export const CONFIG_FIELD_OVERVIEW_ZOOM = 'reviews_overview_zoom';
export const CONFIG_FIELD_CLOSEUP_EFFECTS = 'reviews_closeup_effects';
export const CONFIG_FIELD_LANGUAGE = 'language';
export const CONFIG_FIELD_LIST_HIDE_DUPLICATES = 'noduplicates';
export const CONFIG_FIELD_COMPARISON_ANIMATION_DURATION = 'comparison_animation_duration';
export const DEFAULT_COMPARISON_ANIMATION_DURATION = 1000;
export const CONFIG_FIELD_COMPARISON_IMAGE_SIZE = 'comparison_image_size';
export const DEFAULT_COMPARISON_IMAGE_SIZE = true; // false is small, true is big

export const isNavigationRestricted = () => {
  let result = false;
  const sm = localStorage.getItem(KEY_LS_SIDE_MENU);
  if (sm != null) {
    result = sm == '0';
  }
  return result;
};

export function getConfiguration<Type>(field: string, defaultValue: Type): Type {
  const result: string | null = localStorage.getItem(field);
  if (result != null) {
    if (typeof defaultValue == 'boolean') {
      return (result == 'true' ? true : false) as unknown as Type;
    }
    if (typeof defaultValue == 'number') {
      const num = result.length > 0 ? Number(result) : defaultValue;
      return num as typeof defaultValue;
    }
  }
  return (result != null ? result : defaultValue) as Type;
}

export const setConfiguration = (field: string, newValue) => {
  localStorage.setItem(field, newValue);
};

/**
 *
 * @param strParameter - separated string, where each element has an x instead of =
 */
export const parseConfigParameter = (strParameter) => {
  if (strParameter != null) {
    const arr = strParameter.split('-');
    for (const element of arr) {
      const eltArr = element.split('x');
      if (eltArr.length == 2) {
        let key = '';
        switch (eltArr[0]) {
          case 'src':
            key = KEY_LS_REFERRER;
            break;
          case 'sm':
            key = KEY_LS_SIDE_MENU;
            break;
          case 'dm':
            key = CONFIG_FIELD_OVERVIEW_DISPLAY_MODE;
            break;
          case 'co':
            key = CONFIG_FIELD_OVERVIEW_CANDIDATE_ORDERING;
            break;
          default:
            break;
        }
        if (key != null && key.length > 0) {
          localStorage.setItem(key, eltArr[1]);
          console.log('Set key ' + key + ' to ' + eltArr[1]);
        }
      }
    }

    /*
    } else {
      // Default (web)
      localStorage.setItem(KEY_LS_SIDE_MENU, "1");
      localStorage.setItem(CONFIG_FIELD_OVERVIEW_DISPLAY_MODE, ENUM_FIELD_OVERVIEW_DISPLAY_MODE.CANDIDATES_ONLY+"");
      localStorage.setItem(CONFIG_FIELD_OVERVIEW_CANDIDATE_ORDERING, ENUM_FIELD_OVERVIEW_CANDIDATE_ORDERING.SENTIMENT+"");
    }
*/
  }
};

// TODO: this should be fixed in backend so that workaround is not needed
// Prefixes barcode value with MVA, otherwise prefixes with state (needed for gantry)
// For the moment numbers only, or >7 characters is assumed barcode
export const toTemporaryMVA = (val) => {
  if (val.includes('-')) {
    return val;
  }
  let prefix = 'MVA-';
  if (val.length <= 8) {
    if (/[a-z]/i.test(val)) {
      // At least 1 alpha character
      prefix = '';
    }
  }
  if (isInsurance() && getCountryCode() == COUNTRY_CODES.INDONESIA) {
    // TODO: this needs to be generalised
    prefix = JURISIDICTION_PREFIX;
  }
  return prefix + val;
};

export const getHideDuplicates = (defaultValue?: string[]) => {
  const def =
    typeof defaultValue !== 'undefined' ? defaultValue : isInsurance() ? ['statelicense'] : [];
  const strJson = getConfiguration(CONFIG_FIELD_LIST_HIDE_DUPLICATES, JSON.stringify(def));
  const obj = JSON.parse(strJson);
  return obj;
};

export const getScansReviewListLink = () => {
  return '/scans/review/';
};
