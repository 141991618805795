import { RootState } from './../app.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KEY_LS_ALLOWED_SITES, KEY_LS_USERNAME, KEY_LS_USERTYPE } from '../../utility/constants';

interface IAuthState {
  isAuthenticated: boolean;
}

export const initialState: IAuthState = {
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    signOut: (state) => {
      // Remove keys from cache, except CID (needed for logo display on signin page)
      localStorage.removeItem('authToken');
      localStorage.removeItem(KEY_LS_USERNAME);
      localStorage.removeItem(KEY_LS_ALLOWED_SITES);
      localStorage.removeItem(KEY_LS_USERTYPE);
      state.isAuthenticated = false;
    },
  },
});

// A selector
export const authSelector = (state: RootState) => state.auth;

// Actions
export const { signOut, setCurrentUser } = authSlice.actions;

// The reducer
export default authSlice.reducer;
